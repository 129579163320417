<template>
  <div>
    <!-- <Row class="p-b-10" v-show="isShowCommunity">
        <span class="title">客户类型 </span>
        <RadioGroup v-model="chooseAdvertiserType" type="button" size="small" @on-change="handleTypeChanged">
          <Radio v-for="item in advertiserTypes" :key="item.id" :label="item.id">{{item.name}}</Radio>
        </RadioGroup>
    </Row> -->
    <Row class="p-b-5" :gutter="8">
        <i-col span="16">
            <i-input v-model="query.keyword"  size="small" placeholder="输入关键字，支持名称、品牌和社会信用代码查询"></i-input>
        </i-col>
        <i-col span="3">
            <i-button size="small" type="primary" icon="ios-search" @click="handleKeywordChanged">搜索</i-button>
        </i-col>
    </Row>

    <i-table  stripe  :data="list" :columns="titles"></i-table>
    <div class="paging_style">
      <Page size="small" show-total show-elevator :total="total" :page-size="query.pageSize" @on-change="handlePageChange"></Page>
    </div>

  </div>
</template>

<script>
import { getAvailableAdvertiserPage } from '@/api/crm/advertiser'

export default {
  data () {
    return {
      list: [],
      total: 0,
      query: { pageSize: 5, pageNumber: 1, keyword: '' },
      titles: [
        { title: '客户名称', key: 'name' },
        { title: '统一社会信用代码', key: 'orgCode' },
        { title: '创建时间', key: 'createTime' },
        { title: '类型', width: 100, key: 'typeName' },
        {
          title: '操作',
          width: 90,
          render: (h, data) => {
            if (data.row.id === this.current.id) {
              return h('a', { style: { color: '#ffffff' } }, '已选')
            } else {
              return h('a',
                {
                  on: {
                    click: () => {
                      this.handleCustomerChosen(data.row)
                    }
                  }
                }, '选择')
            }
          }
        }
      ]
      // advertiserTypes: [{ id: 1, name: '商业' }, { id: 2, name: '公益' }],
      // chooseAdvertiserType: 1
    }
  },
  props: {
    userId: {
      type: Number,
      required: true
    },
    publisherId: {
      type: Number,
      required: true
    },
    current: Object,
    isShowCommunity: Boolean
  },
  methods: {
    getPage () {
      this.query.userId = this.userId
      getAvailableAdvertiserPage(this.query).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
      // if (this.chooseAdvertiserType === 1) {

      // } else {
      //   this.query.publisherId = this.publisherId
      //   getCommunityAdvertiserPage(this.query).then(res => {
      //     this.list = res.list
      //     this.total = res.totalRow
      //   })
      // }
    },
    handleKeywordChanged () {
      this.query.pageNumber = 1
      this.getPage()
    },
    handleTypeChanged () {
      this.query.keyword = ''
      this.query.pageNumber = 1
      this.getPage()
    },
    handleCustomerChosen (customer) {
      this.$emit('customerChosen', Object.assign({}, customer, { AdvertiserType: 1 }))
    },
    handlePageChange (page) {
      this.query.pageNumber = page
      this.getPage()
    }
  },
  created () {
    this.getPage()
  }
}
</script>
