<template>
  <div>
      <Row >
          <a type="primary" @click="choiceAdvertiser = true">选择客户</a>
      </Row>

      <div v-if="customer.id">
        <div v-if="customer.AdvertiserType === 1" style="padding: 10px" class="transaction-content">
          <span class="workplatform-title"> {{customer.name}} </span>
          <Row>
            <i-col span="8" style="margin-top: 8px;color:#aeaeae">客户状态 </i-col>
            <i-col span="16">{{customer.companyAdvertiserStatusName }}</i-col>
            <!-- <i-col span="16"><tag  color="blue">{{customer.companyAdvertiserStatusName}}</tag></i-col> -->
          </Row>
          <Row>
            <i-col span="8" class="title">客户类型 </i-col>
            <i-col span="16">{{customer.typeName }}</i-col>
          </Row>
          <Row>
            <i-col span="8"  class="title">所属媒介主 </i-col>
            <i-col span="16">{{customer.publisherName }}</i-col>
          </Row>
          <Row>
            <i-col span="8"  class="title">所属代理商 </i-col>
            <i-col span="16">{{customer.companyName }}</i-col>
          </Row>
          <Row>
            <i-col span="8" class="title">地址 </i-col>
            <i-col span="16">{{customer.provinceName  }}-{{customer.cityName   }}-{{customer.areaName   }}-{{customer.address   }}</i-col>
          </Row>
          <Row>
            <i-col span="8"  class="title">联系方式 </i-col>
            <i-col span="16">{{customer.tel }}</i-col>
          </Row>
          <Row>
            <i-col span="8"  class="title">统一社会信用代码 </i-col>
            <i-col span="16">{{customer.orgCode }}</i-col>
          </Row>
        </div>

        <div v-else style="padding: 10px" class="transaction-content">
          <tag  color="blue">公益客户</tag>
          <Row>
            <i-col span="8"  class="title">客户名称 </i-col>
            <i-col span="16">{{customer.name }}</i-col>
          </Row>
          <Row>
            <i-col span="8"  class="title">地址 </i-col>
            <i-col span="16">{{customer.provinceName  }}-{{customer.cityName   }}-{{customer.areaName   }}-{{customer.address   }}</i-col>
          </Row>
          <Row>
            <i-col span="8"  class="title">联系方式 </i-col>
            <i-col span="16">{{customer.tel }}</i-col>
          </Row>
        </div>
      </div>

    <Form ref="firstPartyForm"  :model="firstParty" :rules="rules" label-position="top">
        <FormItem label="甲方名称" prop="name">
            <i-input size="small" v-model="firstParty.name" placeholder="请输入甲方名称"></i-input>
        </FormItem>
        <FormItem  prop="code">
            <span slot="label">纳税人识别号/社会统一信息代码 <small v-show="repeat" class="text-red">(已有重复的合同甲方)</small> </span>
          <Row :gutter="8">
            <i-col span="18"><i-input  size="small" disabled v-model="firstParty.code" placeholder="格式：91510100MA62L2A037、MA62L2A03、510108600454493" @on-blur="validateCustomer"></i-input></i-col>
          <i-col span="6">
            <Button size="small" type="success" @click="writeOrgCode"
              >自动录入</Button
            >
          </i-col>
          </Row>

        </FormItem>

        <Row :gutter="10">
            <i-col span="12">
              <FormItem label="联系人" prop="contact">
                  <i-input size="small" v-model="firstParty.contact" placeholder="请输入联系人"></i-input>
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="联系电话" prop="tel">
                  <i-input size="small" v-model="firstParty.tel" placeholder="请输入联系方式"></i-input>
              </FormItem>
            </i-col>
             <i-col span="12">
              <FormItem label="电子邮箱" prop="email">
                  <i-input size="small" v-model="firstParty.email" placeholder="请输入电子邮箱"></i-input>
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="传真" prop="fax">
                  <i-input size="small" v-model="firstParty.fax" placeholder="请输入传真"></i-input>
              </FormItem>
            </i-col>
        </Row>
        <FormItem label="甲方地址" prop="address">
            <i-input size="small" v-model="firstParty.address" placeholder="请输入地址"></i-input>
        </FormItem>

        <FormItem label="发票类型" prop="invoiceType">
          <RadioGroup size="small" v-model="firstParty.invoiceType" type="button">
            <Radio v-for="invoice in invoiceTypes" :key="invoice.value" :label="invoice.value">{{invoice.name}}</Radio>
          </RadioGroup>
        </FormItem>

        <Row :gutter="10">
            <i-col span="12">
              <FormItem label="开户行" prop="bank" :required="required" :show-message="false">
                  <i-input size="small" v-model="firstParty.bank" placeholder="请输入 开户行"></i-input>
                  <!-- <small v-show="required && firstParty.bank.length<=0"  class="text-red">(专票客户，开户行不能为空)</small> -->
              </FormItem>
            </i-col>
            <i-col span="12">
              <FormItem label="银行账号" prop="bankAccount" :required="required" :show-message="false">
                  <i-input size="small" v-model="firstParty.bankAccount" placeholder="请输入 银行账号"></i-input>
                  <!-- <small v-show="required && firstParty.bankAccount.length<=0" class="text-red">(专票客户，银行账号不能为空)</small> -->
              </FormItem>
            </i-col>
        </Row>

        <FormItem class="text-right">
            <Button class="btn-black  m-l-8" size="small" @click="handleReset">重置</Button>
            <Button type="success" size="small" class=" m-l-8" :disabled="!customer.id" @click="handleSubmit">保存</Button>
        </FormItem>
      </Form>
     <Modal v-model="choiceAdvertiser" title="选择客户" width="800" :footer-hide="true">
       <customerPicker :userId="$store.getters.token.userInfo.userId" :publisherId="$store.getters.token.userInfo.publisherId"
        :isShowCommunity='$store.getters.token.userInfo.community' :current='customer' @customerChosen="handleChosen"></customerPicker>
    </Modal>
    </div>
</template>

<script>
import customerPicker from '@/components/advertiser/CustomerPicker'

import { addCustomer, checkCustomerRepeat } from '@/api/scp/myfirstparty'
import { getContactList, getAdvertiser } from '@/api/crm/advertiser'
import { queryorgcodebycompanyfullname } from '@/api/scp/invoice'

export default {
  components: {
    customerPicker
  },
  data () {
    return {
      firstParty: {
        name: '',
        address: '',
        tel: '',
        contact: '',
        email: '',
        fax: '',
        invoiceType: 0,
        bank: '',
        bankAccount: '',
        code: ''
      },
      rules: {
        name: [{ required: true, message: ' ' }],
        tel: [{ required: true, message: ' ', trigger: 'blur' }, // （支持手机号码，3-4位区号，7-8位直播号码，1－4位分机号）
          { pattern: /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/, message: '请正确填写联系电话' }
        ],
        contact: [{ required: true, message: ' ' }],
        code: [
          { required: true, message: ' ', trigger: 'blur' },
          { pattern: /^([a-zA-Z0-9]{2}[0-9]{6}[a-zA-Z0-9]{10}|[a-zA-Z0-9]{9}|[a-zA-Z0-9]{15})$/, message: ' ' }
        ]
        // bank: [{ required: true, message: '开户行 不能为空' }],
        // bankAccount: [{ required: true, message: '银行账号 不能为空' }]
      },
      choiceAdvertiser: false,
      customer: {},
      invoiceTypes: [
        { value: 0, name: '专票' },
        { value: 1, name: '普票' }
      ],
      required: true,
      repeat: false,
      oldBank: '',
      oldBankAccount: ''
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.firstPartyForm.validate(valid => {
        if (valid) {
          const firstParty = this.firstParty
          firstParty.advertiserId = this.customer.id
          addCustomer(firstParty).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({ desc: '添加合同甲方成功！' })
              // 更新列表
              this.$store.commit('set_advertiser_update', new Date())
              // 清空当前输入内容
              this.required = true
              this.customer = {}
              this.handleReset()
            }
          })
        }
      })
    },
    writeOrgCode () {
      if (this.firstParty.name) {
        queryorgcodebycompanyfullname({ companyName: this.firstParty.name }).then(res => {
          if (res) {
            this.firstParty.code = res.organizationCode
          } else {
            this.$Notice.error({ desc: '未检索到社会统一信息代码，请确认甲方名称是否正确或手动输入社会统一信息代码' })
          }
        })
      } else {
        this.$Notice.info({ desc: '您未输入甲方名称！' })
      }
    },
    handleReset () {
      this.repeat = false
      this.oldBank = ''
      this.oldBankAccount = ''
      this.$refs.firstPartyForm.resetFields()
    },
    handleChosen (customer) {
      this.customer = customer
      this.handleCustomerChosen(customer)
    },
    handleCustomerChosen (customer) {
      this.firstParty.name = customer.name
      this.firstParty.address = customer.provinceName + customer.cityName + customer.areaName + customer.address
      this.firstParty.code = customer.orgCode
      this.validateCustomer()
      getContactList({ advertiserId: customer.id }).then(res => {
        const first = res[0] || { name: '', phone: '' }
        this.firstParty.contact = first.name
        this.firstParty.tel = first.phone
      })
    },
    validateCustomer () {
      if (this.firstParty.code) {
        checkCustomerRepeat({ code: this.firstParty.code }).then(res => {
          this.repeat = res.length > 0
        })
      }
    }
  },
  created () {
    const advertiserId = this.$route.query.advertiserId
    if (advertiserId) {
      getAdvertiser({ advertiserId: advertiserId }).then(res => {
        this.customer = res
        this.handleCustomerChosen(res)
      })
    }
  },
  watch: {
    'firstParty.bank' (newVal, oldVal) {
      this.oldBank = oldVal
    },
    'firstParty.bankAccount' (newVal, oldVal) {
      this.oldBankAccount = oldVal
    },
    'firstParty.invoiceType': function () {
      this.required = this.firstParty.invoiceType === 0

      if (this.required) {
        this.firstParty.bank = this.oldBank
        this.firstParty.bankAccount = this.oldBankAccount
      }
    }
  }
}
</script>
